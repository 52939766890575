<template>
  <v-content class="view-content" style="background-color: #eee">
    <v-divider />

    <div class="white d-flex align-center" style="height: 57px">
      <v-progress-circular v-if="$isEmpty(items)" indeterminate color="primary" size="20" class="mx-6" />
      <v-breadcrumbs v-else :items="items" key="breadcrumb" />
    </div>

    <v-divider />
    <v-container fluid class="flex-grow-1 px-4">
      <v-overlay :value="loading" opacity="0.1" absolute>
        <v-progress-circular indeterminate color="primary" size="64" />
      </v-overlay>
      <router-view v-show="!loading" />
    </v-container>

    <app-footer />
  </v-content>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'AppView',
  components: {
    AppFooter: () => import('./Footer')
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState(['$breadcrumbs']),
    items() {
      if (!this.$isEmpty(this.$breadcrumbs)) {
        return this.$breadcrumbs
      }
      const length = this.$route.matched.length
      return this.$route.matched.map((item, index) => {
        return {
          to: this.createPath({path: item.path || '/', params: this.$route.params}),
          disabled: index === length - 1,
          exact: true,
          text: this.$t(`nav.${item.meta.label}`)
        }
      })
    }
  },
  methods: {
    createPath(route) {
      let {path, params} = route
      Object.entries(params).forEach(([key, value]) => {
        path = path.replace(`:${key}`, value)
      })
      return path
    }
  }
}
</script>
